<template>
    <div class="guideDetails">

      <!--弹幕-->
      <van-overlay :show="show" >
        <div class="wrapper">
          <div class="block" >
            <div class="blockhead">
              联系导游须知
            </div>
            <p>
              1.联系导游须知

            </p>
            <p>  2.我是须知。</p>
            <div class="blockbtn" @click="show = false">
              我知晓了
            </div>
          </div>
        </div>
      </van-overlay>





      <div class="hd_head">
        <div class="hd_head_fanhui">
          <van-icon name="arrow-left" @click="$router.go(-1)" color="#141212" size="24" />
          <!--<van-icon name="weapp-nav" color="#ffffff"  size="24" />-->
          <img src="./img/jiuhuashantubiao-.png" alt="" @click="show = true">
        </div>
        <van-swipe class="my-swipe" :show-indicators="false" :autoplay="3000" >
          <van-swipe-item>
            <div class="hd_banner">
              <img :src="content.photograph" alt="">
            </div>
          </van-swipe-item>


        </van-swipe>
        <!--<div class="td_head_au">-->
          <!--<div class="td_head_au_left td_head_color">视频(10)</div>-->
          <!--<div class="td_head_au_right">图片(102)</div>-->
        <!--</div>-->
      </div>
      <div class="guideDetails_name">
          <div class="guideDetails_name_left">
            <img :src="content.photograph" alt="">
            <div class="guideDetails_name_left_left">
              <p>导游-{{content.name}}</p>
              <p v-text="content.speciality"></p>
            </div>
          </div>
          <div class="guideDetails_name_right">
            <span>金牌导游榜</span>
            <!--<span>Top. {{content}}</span>-->
          </div>
      </div>
      <div class="td_thd"></div>
      <div class="guideDetails_cont">
        <div class="guideDetails_cont_title">
          基本信息
        </div>
        <div class="guideDetails_cont_head">
          <span >性别：{{content.sex==2?'女':'男'}}</span>
          <span v-if="content.nation==item.value" v-for="item in enumList">民族：{{content.nation==item.value?item.name:''}}</span>
          <span>年龄：{{bridayFunc(content.birthday)}}</span>
        </div>
        <div class="guideDetails_cont_title">
          个人介绍
        </div>
        <div class="guideDetails_cont_txet" v-text="content.synopsis">

        </div>
      </div>
      <div style="margin-top: 14px" class="td_thd"></div>

      <!--<ShcallComment
        :isPraise="content.isPraise"  
        :isCollect="content.isCollect"
        :bizId="params.ciceroneId"
        :teliphone="content.remark"
        :bizType="bizTypes"
        @praise="praise"
        @collect="collect"
        :isConment = 'showInp'
        @commetFunc="commetFunc"
        :star="content.star"
        :collectNum="content.collect"
        :praiseNum="content.praise"
      >
      </ShcallComment>-->
    </div>
</template>

<script src="./guideDetails.js">

</script>

<style scoped src="./guideDetails.css">

</style>
